import { login, getInfo } from '@/net/api/user-api'
import { getAccessToken } from '@/net/api/token-api'
import { getToken, setToken, removeToken } from '@/net/token'
import { resetRouter } from '@/router'
import sha1 from 'js-sha1'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    user: '' // 用户详细信息
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER: (state, user) => {
    state.user = user
  }
}

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: sha1(password) }).then(res => {
        const { success, message, data } = res || {}
        const { access_token: accessToken, expires_in: expiresIn } = data || {}
        if (success && accessToken) {
          // {"access_token":"","token_type":"","refresh_token":"","expires_in":"","scope":"","jti":""}
          const now = new Date().getTime()
          data.expireTime = new Date(now + expiresIn * 1000)
          const token = JSON.stringify(data)
          commit('SET_TOKEN', token)
          setToken(token)
          resolve()
        } else {
          reject(message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  qrcodeLogin ({ commit }, token) {
    return new Promise((resolve, reject) => {
      getAccessToken(token).then(({ success, message, data }) => {
        const { access_token: accessToken, expires_in: expiresIn } = data || {}
        if (success && accessToken) {
          const now = new Date().getTime()
          data.expireTime = new Date(now + expiresIn * 1000)
          const token = JSON.stringify(data)
          commit('SET_TOKEN', token)
          setToken(token)
          resolve()
        } else {
          reject(message)
        }
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },

  updateUserNickname ({ commit, state }, { nickname }) {
    return new Promise(resolve => {
      const u = state.user ? JSON.parse(state.user) : {}
      u.nickname = nickname
      commit('SET_USER', JSON.stringify(u))
      resolve()
    })
  },

  // get user info
  getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { success, message, data } = response
        if (success) {
          commit('SET_NAME', data.username)
          commit('SET_AVATAR', '')
          commit('SET_USER', JSON.stringify(data))
          resolve(response)
        } else {
          reject(message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout ({ commit, state }) {
    return new Promise((resolve, reject) => {
      removeToken() // must remove  token  first
      resetRouter()
      commit('RESET_STATE')
      resolve()
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
