module.exports = {

  title: '智慧内训考评系统',

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  /**
   * form表单label的宽度
   */
  formLabelWidth: '120px',
  /**
   * 微信小程序版本
   */
  wxMiniVersion: 'trial'
}
