<template>
  <el-dialog title="账号管理" v-model="visible" align-center width="30%" :close-on-click-modal="false" @close="onDialogClose">
    <el-form ref="formRef" :model="form" :label-width="formLabelWidth" :rules="formRules">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" auto-complete="off" clearable disabled />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="form.password" type="password" show-password clearable />
      </el-form-item>
      <el-form-item label="新用户名" prop="newUsername">
        <el-input v-model="form.newUsername" auto-complete="off" clearable />
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="form.newPassword" type="password" show-password clearable />
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input v-model="form.confirmPassword" type="password" show-password clearable />
      </el-form-item>
    </el-form>
    <template #footer class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="formLoading" @click="formSubmit">提交</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { accountManager } from '@/net/api/user-api'
import { mapGetters } from 'vuex'
import sha1 from 'js-sha1'

export default {
  name: 'AccountManager',
  data () {
    return {
      formLoading: false,
      form: {
        username: null,
        password: null,
        newUsername: null,
        newPassword: null,
        confirmPassword: null
      },
      formRules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newUsername: [{ required: true, message: '请输入新用户名', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        confirmPassword: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }]
      }
    }
  },
  props: {
    formVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['formLabelWidth', 'userInfo']),
    visible: {
      get () {
        return this.formVisible
      },
      set (val) {
        this.$emit('update:formVisible', val)
      }
    }
  },
  methods: {
    init () {
      this.form = {
        username: this.userInfo.username,
        password: null,
        newUsername: null,
        newPassword: null,
        confirmPassword: null
      }
      this.$refs.formRef.resetFields()
    },
    onDialogClose () {
      this.$refs.formRef.resetFields()
      this.visible = false
    },
    formSubmit () {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (this.form.newPassword !== this.form.confirmPassword) {
            this.$message({
              message: '新密码与确认密码不一致',
              type: 'error'
            })
            return
          }
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            const param = Object.assign({}, {
              username: this.form.username,
              password: sha1(this.form.password),
              newUsername: this.form.newUsername,
              newPassword: sha1(this.form.newPassword)
            })
            this.formLoading = true
            accountManager(param).then(async ({ success, message }) => {
              this.formLoading = false
              if (success) {
                this.visible = false
                this.$message.success(message || '提交成功')
                await this.$store.dispatch('user/logout')
                await this.$store.dispatch('tagsView/delAllViews')
                this.$router.replace('/login')
              } else {
                this.$message.error(message || '提交失败')
              }
            }, () => {
              this.formLoading = false
            })
          }, () => {})
        }
      })
    }
  }
}
</script>
