import axios from '../request'
const base = '/registry/tokens'

export function qrcodeRequest () {
  return axios.post(`${base}/qrcode/request`)
}

export function queryQrcodeStatus (token) {
  return axios.get(`${base}/qrcode/status`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export function getAccessToken (token) {
  return axios.get(`${base}/qrcode/token`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
