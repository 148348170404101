const TOKEN_KEY = 'exam_user_token'

export function getToken () {
  return sessionStorage.getItem(TOKEN_KEY)
}

export function setToken (token) {
  sessionStorage.setItem(TOKEN_KEY, token)
}

export function removeToken () {
  sessionStorage.removeItem(TOKEN_KEY)
}

export function getRawToken () {
  const tokenStr = getToken()
  if (tokenStr) {
    const { access_token: accessToken, expireTime, refresh_token: refreshToken } = JSON.parse(tokenStr)
    return { accessToken, expireTime, refreshToken }
  }
  return {}
}
