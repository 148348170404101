function equals (view1, view2) {
  if (view1 == null && view2 == null) {
    return true
  }
  if (view1 === undefined && view2 === undefined) {
    return true
  }
  if ((view1 == null && view2 === undefined) || (view1 === undefined && view2 == null)) {
    return true
  }
  return view1.name === view2.name
}

function name (view) {
  const { name } = view || {}
  return name
}

function title (view) {
  const { meta = {} } = view || {}

  const { title } = meta
  return title || 'no-name'
}

function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 使用路由中的meta.roles配置的角色判断用户是否有对应路径的访问权限
 * @param roles 用户的角色
 * @param route 路由
 */
function hasPermission (roles, route) {
  if (route.meta && route.meta.roles) {
    if (!roles) {
      return false
    }
    if (roles.includes('admin')) {
      return true
    }
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

export { equals, name, title, isExternal, hasPermission }
