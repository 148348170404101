import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout/Layout'
export const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/LoginView'),
    hidden: true
  }, {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: '首页',
      component: () => import('@/views/HomeView'),
      meta: { title: '首页', icon: 'house', affix: true }
    }]
  }, {
    path: '/system',
    component: Layout,
    redirect: '/system/org-units',
    name: '系统管理',
    meta: { title: '系统管理', icon: 'operation', roles: ['admin_user'] },
    children: [{
      path: 'org-units',
      name: '组织管理',
      component: () => import('@/views/system/OrgUnits'),
      meta: { title: '组织管理', icon: 'grid', roles: ['admin'] }
    }, {
      path: 'users',
      name: '用户管理',
      component: () => import('@/views/system/Users'),
      meta: { title: '用户管理', icon: 'user', roles: ['admin_user'] }
    }]
  }, {
    path: '/exam-center',
    component: Layout,
    redirect: '/exam-center/exam-paper',
    name: '考试中心',
    meta: { title: '考试中心', icon: 'operation' },
    children: [{
      path: 'exam-paper',
      name: '考试列表',
      component: () => import('@/views/exam-center/ExamPaper'),
      meta: { title: '考试列表', icon: 'editPen' }
    }, {
      path: 'recruit',
      name: '招聘笔试',
      component: () => import('@/views/exam-center/Recruitment'),
      meta: { title: '招聘笔试', icon: 'userFilled' }
    }]
  }, {
    path: '/learning',
    component: Layout,
    redirect: '/learning/practice',
    name: '学习园地',
    meta: { title: '学习园地', icon: 'reading' },
    children: [{
      path: 'practice',
      name: '学习记录',
      component: () => import('@/views/learning/Practice'),
      meta: { title: '练习记录', icon: 'aim' }
    }, {
      path: 'practiceRank',
      name: '练习排行榜',
      component: () => import('@/views/learning/PracticeRank'),
      meta: { title: '练习排行榜', icon: 'trendCharts' }
    }]
  }, {
    path: '/question',
    component: Layout,
    redirect: '/question/bank',
    name: '试题中心',
    meta: { title: '试题中心', icon: 'collection' },
    children: [{
      path: 'bank',
      name: '试题库',
      component: () => import('@/views/question/QuestionBank'),
      meta: { title: '试题库', icon: 'notebook' }
    }, {
      path: 'questions',
      name: '试题列表',
      component: () => import('@/views/system/Questions'),
      meta: { title: '试题列表', icon: 'edit' }
    }]
  }, {
    path: '/help',
    component: Layout,
    redirect: '/help/about',
    name: '帮助',
    children: [{
      path: '/about',
      name: '关于',
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      meta: { title: '关于', icon: 'help' }
    }]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter({
    history: createWebHashHistory(),
    routes
  })
  router.matcher = newRouter.matcher // reset router
}

export default router
