export default {
  http: {
    parseFilename: function (res) {
      let filename = ''
      const disposition = res.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
          filename = window.decodeURI(filename)
        }
      }
      return filename
    },
    contentType: function (res) {
      const h = res.headers || {}
      return h['content-type']
    },
    /**
     * 是否返回json内容
     * @param res 响应对象
     * @returns {boolean} true表示是json内容,否则不是
     */
    jsonContent: function (res) {
      const content = this.contentType(res)
      return !!(content && content.indexOf('json') !== -1)
    }
  },
  transform: {
    arrayBuffer2Str: function (arrayBuffer) {
      let str = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
      str = escape(str)
      str = decodeURIComponent(str)
      return str
    }
  },
  downloader: {
    /**
     * 下载文件
     * @param arrayBuffer 文件内容缓冲
     * @param fileName 文件名
     */
    downloadBlob (arrayBuffer, fileName) {
      const blob = new Blob([arrayBuffer])
      if (window.navigator.msSaveBlob) {
        try {
          window.navigator.msSaveBlob(blob, fileName)
        } catch (e) {
          console.log(e)
        }
      } else {
        const fileURL = window.URL.createObjectURL(blob)
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)

        fileLink.click()

        document.body.removeChild(fileLink) // 下载完成移除元素
        window.URL.revokeObjectURL(fileURL) // 释放掉blob对象
      }
    }
  }
}
