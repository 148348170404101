import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { getRawToken, setToken } from './token'
import router from '@/router'
import httpUtils from './http-utils'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // 5秒超时时间
})

// 是否正在刷新令牌标志。
let tokenRefreshing = false
const subscribesArr = []
function subscribesArrRefresh (cb) {
  subscribesArr.push(cb)
}

function reloadSubscribesArr (newToken) {
  subscribesArr.map(cb => cb(newToken))
}

service.interceptors.request.use(config => {
  if (!config.headers.Authorization && store.getters.token) {
    let { accessToken, expireTime, refreshToken } = getRawToken()
    if (expireTime) {
      const ts = new Date(expireTime).getTime() - new Date().getTime()
      if (ts <= 0) {
        // 已经过期
        accessToken = null
      }
      // 有效时间少于10分钟,那刷新令牌
      if (ts < 10 * 60 * 1000 && !tokenRefreshing) {
        tokenRefreshing = true // 由我来刷新就好了,其他请求继续使用之前的token
        const authBase = '/registry'
        service.post(`${authBase}/tokens/refresh`, refreshToken, { headers: { 'Content-Type': 'text/plain' } }).then(res => {
          const { success, message, data } = res || {}
          const { access_token: newToken, expires_in: expiresIn } = data || {}
          if (success && newToken) {
            // {"access_token":"","token_type":"","refresh_token":"","expires_in":"","scope":"","jti":""}
            data.expireTime = new Date(new Date().getTime() + expiresIn * 1000)
            const token = JSON.stringify(data)
            store.commit('user/SET_TOKEN', token)
            setToken(token)
            accessToken = newToken
            reloadSubscribesArr(accessToken)
          } else {
            ElMessage.error(message || '刷新令牌失败')
          }
          tokenRefreshing = false
        }, res => {
          tokenRefreshing = false
        })
        return new Promise((resolve, reject) => {
          subscribesArrRefresh((newToken) => {
            config.headers.Authorization = `Bearer ${newToken}`
            resolve(config)
          })
        })
      }
    }
    // 这边可根据自己的需求设置headers，我司采用Bearer认证
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
  }
  return config
}, error => {
  // 记录日志
  console.log(error)
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const { success, message, code } = response.data || {}
    if (success === true) {
      return response.data
    } else if (success === false) {
      ElMessage.error(message || 'Error')

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (code === 50008 || code === 50012 || code === 50014) {
        // to re-login
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      }
      return Promise.reject(new Error(message || 'Error'))
    } else {
      // json数据,也只返回数据部分
      if (httpUtils.http.jsonContent(response)) {
        if (response.config.responseType === 'arraybuffer') {
          // 文件下载的情况,后台出错了,并传了json返回
          const str = httpUtils.transform.arrayBuffer2Str(response.data)
          const json = JSON.parse(str)
          ElMessage.error(json.message || '下载失败')
          return Promise.reject(new Error(json.message || '下载失败'))
        } else {
          return response.data
        }
      } else {
        return response
      }
    }
  },
  error => {
    console.log('err' + error) // for debug
    const { data, status } = error.response || {}
    const { message } = data || {}
    if (status === 401) {
      store.dispatch('user/logout').then(() => {
        tokenRefreshing = false
        // store.dispatch('tagsView/delAllViews').then(() => {
        router.push('/login')
        // })
      })
      // store.dispatch('user/resetToken').then(() => {
      //   location.reload();
      // });
    } else {
      ElMessage.error(message || error.message || '访问服务器失败')
    }
    return Promise.reject(error)
  }
)

service.all = axios.all
export default service
