<template>
  <el-dialog title="组织二维码" v-model="visible" align-center width="30%" :close-on-click-modal="false">
    <div class="qr-code-container">
      <qrcode :text="qrcodeContent" :size="256"/>
      <p>微信扫一扫二维码加入组织(支持App,抖音扫一扫)</p>
    </div>
    <template #footer class="dialog-footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import qrcode from '@/components/qrcode'

export default {
  name: 'OrgUnitQrcode',
  components: { qrcode },
  data () {
    return {
      qrcodeContent: null
    }
  },
  props: {
    formVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'wxMiniVersion']),
    visible: {
      get () {
        return this.formVisible
      },
      set (val) {
        this.$emit('update:formVisible', val)
      }
    }
  },
  watch: {
    visible (newValue) {
      if (newValue) {
        this.handleQrCode()
      }
    }
  },
  methods: {
    handleQrCode () {
      const id = this.userInfo.currentOrgUnitId
      if (!id || id <= 0) {
        this.$message.error('当前用户不属于任何组织')
        this.visible = false
        return
      }
      this.qrcodeContent = 'https://ruandao.com.cn/exam-code/qrcode.json?fun=2&param=' + id
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code-container {
  text-align: center;
  img {
    display: inline-block;
  }
}
.image-slot {
  width: 100px;
  height: 100px;
}
</style>
