import axios from '../request'
const base = '/registry/user'

export function login (data) {
  return axios.post(`${base}/token`, data)
}

export function getInfo () {
  return axios({
    url: `${base}/loadInfo`,
    method: 'get'
  })
}

export function accountManager (data) {
  return axios.post(`${base}/accountManager`, data)
}

export function listUser (params) {
  return axios.get(`${base}`, { params: params })
}

export function addUser (data) {
  return axios.post(`${base}/add`, data)
}

export function editUser (data) {
  return axios.post(`${base}/edit`, data)
}

export function resetPwd (data) {
  return axios.post(`${base}/resetPwd`, data)
}

export function deleteUser (data) {
  return axios.post(`${base}/delete`, data)
}

export function trans2Admin (data) {
  return axios.post(`${base}/trans2Admin`, data)
}

export function cancelAdmin (data) {
  return axios.post(`${base}/cancelAdmin`, data)
}

export function importFromFile (data) {
  return axios.post(`${base}/importFromFile`, data)
}

export function downloadTemplate () {
  return axios.get(`${base}/template`, { responseType: 'arraybuffer' })
}

export function loadAllUser () {
  return axios.get(`${base}/loadAllUser`)
}

export function userTotalCount () {
  return axios.get(`${base}/count`)
}
