import { createStore } from 'vuex'
import getters from '@/store/getters'

import user from './modules/user-store'
import app from './modules/app-store'
import settings from './modules/settings-store'
import tagsView from './modules/tags-view-store'
import permission from './modules/permission-store'

export default createStore({
  getters,
  modules: {
    user,
    app,
    settings,
    tagsView,
    permission
  }
})
