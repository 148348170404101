<template>
  <div class="navbar">
    <hamburger v-if="false" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <span class="el-dropdown-link user-avatar">欢迎您： {{ username }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <el-dropdown-item @click="accountManager">
              <span style="display:block;">账号管理</span>
            </el-dropdown-item>
            <el-dropdown-item @click="joinOrgUnitQrcode">
              <span style="display:block;">组织二维码</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click="logout">
              <span style="display:block;">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <account-manager v-model:form-visible="accountModifying" ref="accountManagerRef" />
    <org-unit-qrcode v-model:form-visible="orgUnitQrcodeVisible" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import AccountManager from '@/views/components/AccountManager'
import OrgUnitQrcode from '@/layout/components/qrcode/OrgUnitQrcode'
export default {
  name: 'Navbar',
  components: {
    OrgUnitQrcode,
    Breadcrumb,
    Hamburger,
    AccountManager
  },
  data () {
    return {
      accountModifying: false,
      orgUnitQrcodeVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'sidebar', 'userInfo'
    ]),
    username () {
      return this.userInfo.username
    },
    adminUser () {
      const roles = this.userInfo.roles || []
      return roles.indexOf('admin_user') !== -1
    }
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout () {
      await this.$store.dispatch('user/logout')
      await this.$store.dispatch('tagsView/delAllViews')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      this.$router.push('/login')
    },
    accountManager () {
      this.accountModifying = true
      this.$nextTick(() => {
        this.$refs.accountManagerRef.init()
      })
    },
    joinOrgUnitQrcode () {
      this.orgUnitQrcodeVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: transparent;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      vertical-align: middle;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
