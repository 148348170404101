<template>
  <div ref="qrcode"></div>
</template>
<script>
import * as QRCode from 'easyqrcodejs'
import logoUrl from '../assets/logo.png'
export default {
  name: 'Qrcode',
  props: {
    text: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      qrcode: null
    }
  },
  watch: {
    text: function () {
      if (this.qrcode) {
        this.qrcode.makeCode(this.text)
      } else {
        this.$message.error('请稍候重试')
      }
    }
  },
  mounted: function () {
    const options = {
      text: this.text,
      width: this.size,
      height: this.size,
      correctLevel: QRCode.CorrectLevel.H,
      logo: logoUrl
    }
    this.qrcode = new QRCode(this.$refs.qrcode, options)
  }
}
</script>
<style scoped>
</style>
